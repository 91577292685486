import React from 'react';
import { createRoot } from 'react-dom/client'; // Importa createRoot desde "react-dom/client"
import App from './App';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Importa Routes

// Utiliza createRoot en lugar de ReactDOM.render
createRoot(document.getElementById('root')).render(
    <Router>
        <Routes> {/* Usa el componente Routes para envolver las rutas */}
            <Route path="/zass/:id" element={<App />} /> {/* Usa el atributo element */}
        </Routes>
    </Router>
);
