/* Let CRA handle linting for sample app */
import React, { useState, Component } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from 'react-spinner';
import classNames from 'classnames';
import AccCore from 'opentok-accelerator-core';
import 'opentok-solutions-css';
import logo from './assets/images/logo.png';
import tusicologo from './assets/images/tusicologo.svg';
import config from './config.json';
import './App.css';
import axios from 'axios';
let otCore;
const otCoreOptions = {
  credentials: {
    apiKey: config.apiKey,
    sessionId: config.sessionId,
    token: config.token,
  },
  // A container can either be a query selector or an HTML Element
  streamContainers(pubSub, type, data, stream) {
    return {
      publisher: {
        camera: '#cameraPublisherContainer',
        screen: '#screenPublisherContainer',
      },
      subscriber: {
        camera: '#cameraSubscriberContainer',
        screen: '#screenSubscriberContainer',
      },
    }[pubSub][type];
  },
  controlsContainer: '#controls',
  packages: ['screenSharing', 'annotation'],
  communication: {
    callProperties: null, // Using default
  },
  textChat: {
    name: ['David', 'Paul', 'Emma', 'George', 'Amanda'][Math.random() * 5 | 0], // eslint-disable-line no-bitwise
    waitingMessage: 'Messages will be delivered when other users arrive',
    container: '#chat',
  },
  screenSharing: {
    extensionID: 'plocfffmbcclpdifaikiikgplfnepkpo',
    annotation: true,
    externalWindow: false,
    dev: true,
    screenProperties: {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false,
      style: {
        buttonDisplayMode: 'on',
      },
      videoSource: 'window',
      fitMode: 'contain' // Using default
    },
  },
  annotation: {
    absoluteParent: {
      publisher: '.App-video-container',
      subscriber: '.App-video-container'
    }
  },
};


const withParams = WrappedComponent => {
  return props => {
    const params = useParams();
    return <WrappedComponent {...props} params={params} />;
  };
};

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled } = state;
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;
  return {
    controlClass: classNames('App-control-container', { hidden: !active }),
    localAudioClass: classNames('ots-video-control circle audio', { hidden: !active, muted: !localAudioEnabled }),
    localVideoClass: classNames('ots-video-control circle video', { hidden: !active, muted: !localVideoEnabled }),
    localCallClass: classNames('ots-video-control circle end-call', { hidden: !active }),
    cameraPublisherClass: classNames('video-container', { hidden: !active, small: !!activeCameraSubscribers || screenshareActive, left: screenshareActive }),
    screenPublisherClass: classNames('video-container', { hidden: !active || !sharingScreen }),
    cameraSubscriberClass: classNames('video-container', { hidden: !active || !activeCameraSubscribers },
      { 'active-gt2': activeCameraSubscribersGt2 && !screenshareActive },
      { 'active-odd': activeCameraSubscribersOdd && !screenshareActive },
      { small: screenshareActive },
      { right: screenshareActive }
    ),
    screenSubscriberClass: classNames('video-container', { hidden: !viewingSharedScreen || !active }),
  };
};

const connectingMask = () =>
  <div className="App-mask">
    <Spinner />
    <div className="message with-spinner"></div>
  </div>;
const StartCallMask = ({ start }) => { // Changed to a functional component
  const [clicked, setClicked] = useState(false); // Using useState hook
  const [classerror, setClasserror] = useState(''); // Using useState hook

  const handleClick = () => {
    if (!clicked && isChecked == true) {
      start();
      setClicked(true);
    } else {
      setClasserror('text-red-500')
    }
  };
  const [isChecked, setIsChecked] = useState();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };


  return (
    <div className="App-mask w-full flex flex-col items-center justify-center gap-4">

      <div>
        <img src={tusicologo} className="w-34" alt="tusicologo" />
      </div>
      <div className='h-[60%] w-[70%] text-white flex flex-col gap-2 overflow-auto text-justify p-6'>
        <p>
          Yo, con capacidad absoluta para ejercer derechos y contraer obligaciones y con pleno uso de mis facultades manifiesto mi aceptación del proceso de evaluación y tratamiento ofrecido por TuSicologo.com.
        </p>
        <p>
          Entiendo que toda la información concerniente a la evaluación y tratamiento es confidencial y no será divulgada ni entregada a ninguna otra institución o individuo sin mi consentimiento, excepto cuando la orden de entrega provenga de una autoridad judicial competente. También entiendo y acepto que el principio de confidencialidad tiene un límite si se presentaran situaciones que pongan en grave peligro mi integridad física o mental, o la de cualquier otra persona, en cuyo caso, el profesional está obligado a revelar dicha información a mis personas cercanas o a las autoridades competentes. La valoración de la gravedad de la situación que permitirá establecer el límite de confidencialidad, será determinada por el profesional en psicología en concepto escrito o verbal que será puesto en mi conocimiento, pero no tiene que contar con mi aceptación expresa.
        </p>
        <p>
          Autorizo para que el profesional consulte mi caso con otros profesionales, terceros expertos, o remitirlo a consulta con especialista para brindar el mejor tratamiento posible. El resultado de dicha consulta me será comunicado verbalmente.
        </p>
        <p>
          De la misma manera, autorizo al profesional hacer uso de instrumentos de evaluación, que serán comunicadas verbalmente con anterioridad a su aplicación y cuyos resultados me serán explicados.
        </p>
        <p>
          Entiendo que el profesional a cargo del proceso terapéutico conservará los lineamientos profesionales del psicólogo, y se me ha explicado los siguientes puntos:
        </p>
        <p>
          - El profesional a cargo utilizará las medidas necesarias para salvaguardar la información confidencial del caso.
        </p>
        <p>
          - Se mantendrán los límites adecuados entre la comunicación profesional y la privacidad personal en redes sociales.
        </p>
        <p>
          - Todas las actividades en línea se realizarán dentro de la plataforma de tusicologo.com para mantener la privacidad del caso.
        </p>
        <p>
          - El espacio físico y los medios electrónicos donde se prestará el servicio, será un lugar apropiado, no permitiendo la interrupción de terceros, así como la posible filtración de información confidencial del caso.
        </p>
        <p>
          Entiendo que el éxito en el cumplimiento de los objetivos planteados para el proceso terapéutico depende de mí cumplimiento tanto de la asistencia a las sesiones de evaluación e intervención, como de la realización oportuna de los ejercicios sugeridos por el terapeuta.
        </p>
        <p>
          Manifiesto que comienzo este proceso terapéutico con suficiente conocimiento y sin haber sido obligado o forzado. Mantendré a mi terapeuta completamente informado sobre cambios en mis sentimientos, pensamientos y conductas. Autorizo para que trabajemos conjuntamente en cualquier dificultad que ocurra, teniendo siempre en cuenta lo que más me conviene a largo plazo. Comprendo que el terapeuta se compromete a utilizar estrategias de evaluación e intervención que han demostrado ser eficaces mediante evidencia científica, a usar y compartir su conocimiento y habilidades de buena fe.
        </p>
        <p>
          En forma expresa manifiesto que he leído y comprendido íntegramente este documento y en consecuencia acepto su contenido y las consecuencias que de éste se deriven. También me informaron que puedo revocar mi consentimiento; cuando desee firmando un desistimiento informado en cualquier momento que lo desee. He leído, comprendido y accedido a lo anteriormente mencionado.
        </p>

      </div>
      <label className={`flex flex-row gap-3 text-white ${!isChecked ? classerror : ''}`}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        Aceptar términos y condiciones
      </label>

      {/* {!isChecked && <p className="text-red-500 mt-2">Debes aceptar los términos y condiciones</p>} */}

      <button className="btn-ingress" onClick={handleClick} disabled={clicked}>
        Ingresar
      </button>
    </div>
  );
};

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
    };
    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
  }

  async componentDidMount() {
    const { params } = await this.props;
    const { data } = await axios.get("https://apiprod.tusicologo.com/api/auth/get_meeting_zaas/" + params.id);
    otCoreOptions.credentials.apiKey = data.key
    otCoreOptions.credentials.sessionId = data.sessionId
    otCoreOptions.credentials.token = data.token
    otCore = new AccCore(otCoreOptions);
    otCore.connect().then(() => this.setState({ connected: true }));
    const events = [
      'subscribeToCamera',
      'unsubscribeFromCamera',
      'subscribeToScreen',
      'unsubscribeFromScreen',
      'startScreenShare',
      'endScreenShare',
    ];

    events.forEach(event => otCore.on(event, ({ publishers, subscribers, meta }) => {
      this.setState({ publishers, subscribers, meta });
    }));
  }

  startCall() {
    otCore.startCall()
      .then(({ publishers, subscribers, meta }) => {
        this.setState({ publishers, subscribers, meta, active: true });
      }).catch(error => console.log(error));
  }

  endCall() {
    console.log("Saliendo")
    try {
      otCore.endCall();
    } catch (error) {
      console.log(error)
    }
    // this.setState({ active: false });
    window.location = 'https://call.tusicologo.com/questions'
    // navigate('/questions');
  }

  toggleLocalAudio() {
    otCore.toggleLocalAudio(!this.state.localAudioEnabled);
    this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
  }

  toggleLocalVideo() {
    otCore.toggleLocalVideo(!this.state.localVideoEnabled);
    this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
  }

  render() {
    const { connected, active } = this.state;
    const {
      localAudioClass,
      localVideoClass,
      localCallClass,
      controlClass,
      cameraPublisherClass,
      screenPublisherClass,
      cameraSubscriberClass,
      screenSubscriberClass,
    } = containerClasses(this.state);

    return (
      <div className="App">
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div className="App-main flex flex-col justify-center items-center">
          <div className="App-video-container flex flex-col gap-4">
            {!connected && connectingMask()}
            {connected && !active && <StartCallMask start={this.startCall} />}
            <div id="cameraPublisherContainer" className={cameraPublisherClass} />
            <div id="screenPublisherContainer" className={screenPublisherClass} />
            <div id="cameraSubscriberContainer" className={cameraSubscriberClass} />
            <div id="screenSubscriberContainer" className={screenSubscriberClass} />
          </div>
          <div id="controls" className={controlClass}>
            <div className={localAudioClass} onClick={this.toggleLocalAudio} />
            <div className={localVideoClass} onClick={this.toggleLocalVideo} />
            <div className={localCallClass} onClick={this.endCall} />
          </div>
          <div id="chat" className="App-chat-container" />
        </div>
      </div>
    );
  }
}
export default withParams(App);
